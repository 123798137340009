import React, { Fragment } from "react";
import HeaderBotton from "../../../components/HeaderBottom";
import HeaderTop from "../../../components/HeaderTop";
import Breadcumb from "../../../components/Breadcumb";
import NewsLetter from "../../../components/Newsletter";
import FooterArea from "../../../components/FooterArea";
import Form from "../../../components/Form";
// images
import breadcumb from "../../../images/breadcumb/1.jpg";

import "./style.scss";

const breadcumbMenu = [{ name: "Home", route: "/" }, { name: "Contact" }];

const ContactPage = () => {
  return (
    <Fragment>
      <header className="headerArea">
        <HeaderTop className="headerTop" />
        <HeaderBotton className="headerBottomArea headerBottomAreaStyelTwo" />
      </header>
      <Breadcumb
        className="breadcumbArea"
        title="Contact"
        breadcumbMenu={breadcumbMenu}
        background={breadcumb}
      />

      <div className="contactusPageArea">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div className="contactUsInfo">
                <h3>Datele noastre de contact</h3>
                <p>
                  Contrary to popular belief, Lorem Ipsum is not simply random
                  text. It has roots in a piece of classical Latin literature
                  from 45 BC, making it over 2000 years old.
                </p>
                <h4>Adresa</h4>
                <span>Strada Ion Maiorescu 12, Blocul 33S1</span>
                <h4>Telefon</h4>
                <span>+40745367274</span>

                <h4>E-mail</h4>
                <span>av.surugiu@legalbiz-consulting.ro</span>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="contactUSForm">
                <h3>Formular de contact</h3>
                <Form addressInfo={true} />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <NewsLetter className="newsLetterArea" /> */}
      <FooterArea />
    </Fragment>
  );
};
export default ContactPage;
