import React from "react";
import { NavLink, Link } from "react-router-dom";
import "./style.scss";
const HeaderTop = (props) => {
  return (
    <div className={props.className}>
      <div className="container d-none d-lg-block">
        <div className="headerTopMainWrapper">
          <div className="row">
            <div className="col-md-3 col-sm-12 col-12 col-lg-5">
              <ul className="d-flex accountLoginArea">
                <li>
                  <i className="fa fa-map-marker" aria-hidden="true"></i>Strada
                  Ion Maiorescu 12, Blocul 33S1, Ploiesti
                </li>
              </ul>
            </div>
            <div className="col-md-6 col-sm-12 col-12 col-lg-5">
              <ul className="headerContact">
                <li>
                  <i className="fa fa-phone"></i>+40 745 367 274
                </li>
                <li>
                  <i className="fa fa-clock-o"></i>09:00 - 18:00
                </li>
              </ul>
            </div>
            <div className="col-lg-2 col-md-3 col-sm-12">
              <div className="btnStyle btnStyle2 text-right">
                <Link to="/contact">Contact</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default HeaderTop;
