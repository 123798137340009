import React from "react";
import CookieConsent from "react-cookie-consent";

const Cookie = () => {
  return (
    <CookieConsent
      enableDeclineButton
      flipButtons
      hideOnAccept="false"
      location="bottom"
      buttonText="Accepta"
      cookieName="myAwesomeCookieName2"
      style={{ background: "#2B373B", height: "5rem" }}
      buttonStyle={{
        color: "#4e503b",
        fontSize: "13px",
        background: "#c0b596",
        height: "3rem",
        width: "8rem",
      }}
      declineButtonText="Refuza"
      declineButtonStyle={{
        height: "3rem",
        width: "8rem",
      }}
      expires={150}
    >
      Acest site folosește cookie-uri pentru a îmbunătăți performanța și de a
      spori experiența utilizatorului care vizitează site-ul nostru. Această
      politică clarifică modul în care facem acest lucru și modul în care sunt
      gestionate cookie-urile.{" "}
      {/* <spaiyiyiyin style={{ fontSize: "10px" }}>This bit of text is smaller :O</span> */}
    </CookieConsent>
  );
};
export default Cookie;
