import React from "react";
import Form from "../Form";
import "./style.scss";
const ContactArea = ({ className }) => {
  return (
    <div className={className}>
      <div className="container">
        <div className="row">
          <div className="col-lg-5 col-12">
            <div className="contactInfo">
              <span>Formular de contact pentru clienti noi</span>
              <h2>Formular de contact</h2>
              <h6>Da-ne un telefon, sau foloseste formularul</h6>
              <p>Lorem Impsum</p>
            </div>
          </div>
          <div className="col-12 col-lg-7">
            <Form />
          </div>
        </div>
      </div>
    </div>
  );
};
export default ContactArea;
