import React, { Fragment } from "react";
import HeaderBotton from "../../../components/HeaderBottom";
import HeaderTop from "../../../components/HeaderTop";
import HeroSlider from "../../../components/HeroSlider";
import Service from "../../../components/Service";
import About from "../../../components/About";
import ServiceArea from "../../../components/ServiceArea";
import Portfolio from "../../../components/Portfolio";
import Testmonial from "../../../components/Testmonial";
import ContactArea from "../../../components/ContactArea";
import TeamMember from "../../../components/TeamMember";
import CounterArea from "../../../components/CounterArea";
import BlogArea from "../../../components/BlogArea";
import FooterArea from "../../../components/FooterArea";
import Cookie from "../../../components/Cookie";
// images
import about from "../../../images/about/2.jpg";
import signature from "../../../images/about/1.png";

// images
import portfolio1 from "../../../images/studies/1.jpg";
import portfolio2 from "../../../images/studies/2.jpg";
import portfolio3 from "../../../images/studies/3.jpg";
import portfolio4 from "../../../images/studies/4.jpg";
import portfolio5 from "../../../images/studies/5.jpg";

const aboutText = [
  {
    text:
      "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at ,",
  },
  {
    text:
      'and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum',
  },
];

const heroSliders = [
  {
    images: "slideWrapperOne",
    title: "Luptam pentru tine",
    subTitle: "Ca un prieten",
    text: "Servicii profesionale",
    button: "Contacteaza-ne",
  },
  {
    images: "slideWrapperTwo",
    title: "Luptam pentru tine",
    subTitle: "Avocati cu experienta",
    text: "Servicii profesionale",
    button: "Contactaza-ne",
  },
];

const services = [
  {
    icon: "flaticon-parents",
    title: "Serciviu 1",
    content:
      "It is a long established fact that a reader will be distracted by the readable content of ",
  },
  {
    icon: "flaticon-wounded",
    title: "Serciviu 2",
    content:
      "It is a long established fact that a reader will be distracted by the readable content of ",
  },
  {
    icon: "flaticon-employee",
    title: "Serciviu 3",
    content:
      "It is a long established fact that a reader will be distracted by the readable content of ",
  },
  {
    icon: "flaticon-thief",
    title: "Serciviu 4",
    content:
      "It is a long established fact that a reader will be distracted by the readable content of ",
  },
  {
    icon: "flaticon-university-graduate-hat",
    title: "Serciviu 5",
    content:
      "It is a long established fact that a reader will be distracted by the readable content of ",
  },
  {
    icon: "flaticon-house",
    title: "Serciviu 6",
    content:
      "It is a long established fact that a reader will be distracted by the readable content of ",
  },
];

const portfolioItem = [
  { images: portfolio1, title: "General Service", subtitle: "Corporate" },
  { images: portfolio2, title: "Personal Issue", subtitle: "General" },
  { images: portfolio3, title: "Business Accounting", subtitle: "Business" },
  { images: portfolio4, title: "Accounting issue", subtitle: "Criminal" },
  {
    images: portfolio5,
    title: "Business Accounting",
    subtitle: "Family Issue",
  },
];

const HomePageOne = () => {
  return (
    <Fragment>
      <header className="headerArea">
        <HeaderTop className="headerTop" />
        <HeaderBotton className="headerBottomArea" />
      </header>
      <HeroSlider sliders={heroSliders} className="heroSliderArea" />
      <Service className="serviceArea" />
      <About
        className="aboutArea"
        title="Despre noi"
        images={about}
        signature={signature}
        pragraphs={aboutText}
      />
      <ServiceArea
        className="ourServiceArea"
        title="Cum te putem ajuta"
        subTitle="Aria de expertiza"
        services={services}
      />
      <Portfolio
        className="portfolioArea"
        title="Cele mai recente studii de caz"
        subTitle="Munca noastra"
        portfolioItem={portfolioItem}
      />
      <Testmonial className="testmonialArea" />
      <ContactArea className="contactArea" />
      <TeamMember
        title="Avocati calificati"
        subTitle="Intalneste-te cu expertii"
        className="teamArea"
        slider={true}
      />
      <CounterArea className="counterArea" />
      {/* <BlogArea
        className="blogArea"
        title="Latest News"
        subTitle="From Our Blog
                "
      /> */}
      <FooterArea />
      <Cookie />
    </Fragment>
  );
};
export default HomePageOne;
